import React, { useEffect, useMemo, useState } from 'react';
import PageLoader from '../../../Loaders/PageLoader/PageLoader';
import { CustomToast } from '../../../Notifications/CustomToast';
import '../../../../assets/css/ProductDetail.css';
import { formatDateSpanish } from '../../../../util/date';
import { cancelAllRequests } from '../../../../util/axios-req';
import ImageViewer from '../../../Images/ImageViewer';
import { Button } from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CustomSelect from '../../../Inputs/CustomSelect/CustomSelect';
import StarRating from '../../../Reviews/StarRating/StarRating';
import SheinService from '../../../../services/shein.service';
import { calculateSheinPrice } from '../../../../util/price';
import { SheinProductCard } from '../../Card/Shein/SheinProductCard';
import { itemExistsInCart } from '../../../../util/cart';
import { useAuth } from '../../../../hooks/useAuth';
import FirebaseService from '../../../../services/firebase.service';
import ProductReportModal from '../../Modals/ReportModal/ProductReportModal';
import { limitWords } from '../../../../util/string';
import { MAX_PRODUCT_PRICE } from '../../../../contants';

export default function SheinDetail({ goodsId }) {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(null);
  const [recommendations, setRecommendations] = useState([]);
  const [color, setColor] = useState(null);
  const [size, setSize] = useState(null);
  const [productReportModal, setProductReportModal] = useState(false);

  const { profile, cart, setCart } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const sheinService = SheinService.getInstance();
  const firebaseService = FirebaseService.getInstance();

  const params = new URLSearchParams(location.search);
  const sizeParam = params.get('sku');

  useEffect(() => {
    const fetchData = async () => {
      if (loading || !goodsId || product?.goods_id === goodsId) return;
      try {
        resetValues();
        setLoading(true);
        const productPromise = searchProduct();
        await Promise.all([productPromise]);
      } catch {
        CustomToast('error', 'Error al buscar productos');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [goodsId]);

  const colorOptions = useMemo(() => {
    if (!product) return null;
    return product?.related_color_goods;
  }, [product]);

  const sizeOptions = useMemo(() => {
    if (!product) return null;
    return product?.multiLevelSaleAttribute;
  });

  useEffect(() => {
    if (!product) return;
    console.debug(product);
    if (colorOptions && colorOptions?.length > 0) {
      setColor({
        value: colorOptions?.find((item) => item.goods_id === product.goods_id)
          .goods_id,
        label: colorOptions?.find((item) => item.goods_id === product.goods_id)
          .goods_color_name,
      });
    }
    if (sizeParam && sizeOptions && sizeOptions?.skc_sale_attr?.length > 0) {
      setSize({
        value: sizeParam,
        label: sizeOptions.skc_sale_attr[0]?.attr_value_list.find(
          (item) => item.attr_value_id === sizeParam,
        )?.attr_value_name,
      });
    }
    if (!sizeParam && sizeOptions && sizeOptions?.skc_sale_attr?.length > 0) {
      setSize({
        value: sizeOptions.skc_sale_attr[0]?.attr_value_list[0]?.attr_value_id,
        label:
          sizeOptions.skc_sale_attr[0]?.attr_value_list[0]?.attr_value_name,
      });
    }
  }, [product, colorOptions, sizeOptions]);

  useEffect(() => {
    if (!color?.value || color?.value === product?.goods_id) return;
    navigate(`/detail/${color.value}`, { replace: true });
  }, [color]);

  useEffect(() => {
    if (!size?.value || size?.value === product?.goods_id) return;
    navigate(`/detail/${product.goods_id}?size=${size.value}`, {
      replace: true,
    });
  }, [size]);

  useEffect(() => {
    if (!product || recommendations.length > 0) return;
    searchRecomendations();
  }, [product, recommendations]);

  const resetValues = async () => {
    cancelAllRequests();
    setProduct(null);
    setRecommendations([]);
    setColor(null);
    setSize(null);
  };

  const searchProduct = async () => {
    try {
      let detailResponse = await sheinService.detail(goodsId);
      if (!detailResponse.info)
        detailResponse = await sheinService.legacyDetail(goodsId);
      let extraInfoResponse = await sheinService.extraInfo(goodsId);
      if (!extraInfoResponse.info)
        extraInfoResponse = await sheinService.legacyExtraInfo(goodsId);
      setProduct(
        {
          ...detailResponse.info,
          ...extraInfoResponse.info,
        } ?? null,
      );
    } catch {
      CustomToast('error', 'Error al buscar producto');
    }
  };

  const searchRecomendations = async () => {
    if (!product?.goods_name) return;
    try {
      const query = limitWords(product.goods_name, 5);
      const response = await sheinService.search(query);
      if (response.info.products.length > 0)
        setRecommendations(response.info.products.slice(0, 10));
    } catch {
      CustomToast('error', 'Error al buscar recomendaciones');
    }
  };

  const handleAddToCart = async () => {
    if (!product) return;
    if (colorOptions && colorOptions?.length > 0 && !color) {
      CustomToast('warning', 'Por favor selecciona un color');
      return;
    }
    if (sizeOptions && sizeOptions?.skc_sale_attr?.length > 0 && !size) {
      CustomToast('warning', 'Por favor selecciona una talla/capacidad/tamaño');
      return;
    }
    try {
      setLoading(true);
      if (itemExistsInCart(cart.items, product)) {
        CustomToast('warning', 'Producto ya se encuentra en el carrito');
        return;
      }
      const productToAdd = {
        ...product,
        priceCalculate: salePrice,
        estimateDeliveryDate: estimateDeliveryDate?.toISOString(),
        travelerSelect: null,
        variant: null,
        color,
        size,
        ttl: parseFloat(process.env.REACT_APP_CART_TTL),
        createdAt: Date.now(),
      };
      const items = [...cart.items, productToAdd];
      if (profile)
        await firebaseService.updateCart(profile, { ...cart, items });
      setCart({ ...cart, items });
      CustomToast('success', 'Producto agregado al carrito');
    } catch {
      CustomToast('error', 'Error al agregar producto al carrito');
    } finally {
      setLoading(false);
    }
  };

  const handlePurchase = async () => {
    handleAddToCart();
    navigate('/checkout');
  };

  const salePrice = useMemo(() => {
    if (!product) return null;
    let price = parseFloat(product?.sale_price?.amount);
    if (sizeOptions?.sku_list?.length > 0 && size) {
      const matchingItem = sizeOptions?.sku_list.find((item) =>
        item.sku_sale_attr.some((attr) => attr.attr_value_id === size.value),
      );
      price = parseFloat(matchingItem?.price?.salePrice?.amount);
    }
    return price && price <= MAX_PRODUCT_PRICE
      ? calculateSheinPrice(price, true)
      : null;
  }, [product, sizeOptions, size]);

  const originalPrice = useMemo(() => {
    if (!product) return null;
    let price = parseFloat(product?.retail_price?.amount);
    if (sizeOptions?.sku_list?.length > 0 && size) {
      const matchingItem = sizeOptions?.sku_list.find((item) =>
        item.sku_sale_attr.some((attr) => attr.attr_value_id === size.value),
      );
      price = parseFloat(matchingItem?.price?.retailPrice?.amount);
    }
    return price && price <= MAX_PRODUCT_PRICE
      ? calculateSheinPrice(price)
      : null;
  }, [product, sizeOptions, size]);

  const discountPercentage = useMemo(() => {
    if (!salePrice || !originalPrice) return null;
    return ((1 - salePrice / originalPrice) * 100).toFixed(2);
  }, [salePrice, originalPrice]);

  const estimateDeliveryDate = useMemo(() => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 20);
    return currentDate;
  }, [product]);

  if (loading || !product) return <PageLoader />;

  return (
    <div className="detail-container">
      <div className="top-section">
        {product?.nowater_gallery?.detail_image?.length && (
          <div className="image-gallery">
            <ImageViewer
              images={
                product?.nowater_gallery?.detail_image?.length > 0 &&
                product?.nowater_gallery?.detail_image.map((item, index) => ({
                  src: item?.origin_image,
                  description: index,
                }))
              }
            />
          </div>
        )}

        <div className="product-details">
          <h1 className="product-title">{product?.goods_name}</h1>
          {product?.comments_overview?.comment_rank_average && (
            <div className="section-container">
              <StarRating
                rating={product.comments_overview?.comment_rank_average}
              />
            </div>
          )}
          <div className="price-container">
            <div className="price-section">
              {discountPercentage > 0 && (
                <span className="discount-percentage">
                  -{discountPercentage}%
                </span>
              )}
              {salePrice && (
                <span className="discount-price">${salePrice}</span>
              )}
            </div>
            {originalPrice && originalPrice !== salePrice && (
              <div className="price-section">
                <span className="original-price-title">Precio original: </span>
                <span className="original-price">${originalPrice}</span>
              </div>
            )}
          </div>

          {colorOptions && colorOptions.length > 0 && (
            <div className="section-container">
              <p className="section-title">Colores:</p>
              <CustomSelect
                value={color}
                setValue={setColor}
                options={colorOptions?.map((item) => {
                  return {
                    value: item.goods_id,
                    label: item.goods_color_name,
                  };
                })}
              />
            </div>
          )}

          {sizeOptions && sizeOptions?.skc_sale_attr?.length > 0 && (
            <div className="section-container">
              <p className="section-title">Tallas/Capacidades/Tamaños:</p>
              <CustomSelect
                value={size}
                setValue={setSize}
                options={sizeOptions.skc_sale_attr.flatMap((attr) =>
                  attr?.attr_value_list?.map((item) => ({
                    value: item.attr_value_id,
                    label: item.attr_value_name,
                  })),
                )}
              />
            </div>
          )}

          {product?.description && (
            <div className="section-container">
              <p className="section-title">Sobre este producto:</p>
              <p>{product?.description}</p>
            </div>
          )}
        </div>

        <div className="purchase-options">
          {salePrice ? (
            <>
              <p className="section-title">Comprar nuevo:</p>
              <h2>${salePrice}</h2>
              <p>En existencias</p>
              {estimateDeliveryDate && (
                <p className="delivery-date">
                  Fecha Estimada de entrega:{' '}
                  <b>{formatDateSpanish(estimateDeliveryDate)}</b>
                </p>
              )}
              <div className="purchase-buttons">
                <Button className="primaryBtn" onClick={handleAddToCart}>
                  Agregar a carrito
                </Button>
                <Button className="secondaryBtn" onClick={handlePurchase}>
                  Comprar ahora
                </Button>
              </div>
            </>
          ) : (
            <>
              <p className="section-title">Este producto no esta disponible</p>
              <Button
                className="primaryBtn"
                onClick={() => setProductReportModal(true)}
              >
                Solicitar cotizacion
              </Button>
            </>
          )}
        </div>
      </div>

      <div className="bottom-section">
        {product?.productDetails?.length > 0 && (
          <div className="product-specifications">
            <p className="section-title">Detalles del producto:</p>
            {product?.productDetails?.map((item, index) => (
              <div key={index} className="product-specifications-item">
                <p>{item.attr_name}</p>
                <p>{item.attr_value}</p>
              </div>
            ))}
          </div>
        )}

        {recommendations?.length > 0 && (
          <div className="product-recommendations">
            <p className="section-title">Recomendaciones:</p>
            <div className="recommendations-carousel">
              {recommendations.map((item, index) => (
                <div key={index} className="recommendation-item">
                  <SheinProductCard key={index} productDefault={item} />
                </div>
              ))}
            </div>
          </div>
        )}

        {product?.product_comments?.length > 0 && (
          <div className="product-reviews">
            <p className="section-title">Reseñas principales</p>
            {product?.product_comments?.map((item, index) => (
              <div key={index} className="product-review-item">
                <div className="product-review-header">
                  <img
                    src={`https://eu.ui-avatars.com/api/?name=${item.user_name}&size=250`}
                    alt={`${item.user_name}'s profile`}
                    className="profile-image"
                  />
                  <Link to={''} rel="noreferrer">
                    {item.user_name}
                  </Link>
                </div>
                <div className="product-review-rating">
                  <StarRating rating={item.comment_rank} />
                  <p style={{ fontWeight: 'bold' }}>
                    {item.commentTimeNewStyle}
                  </p>
                </div>
                <p className="product-review-date">
                  {new Date(item.comment_time).toLocaleDateString('de-DE')}
                  {product.goods_id === item.goods_id && (
                    <span>&nbsp;| Compra verificada</span>
                  )}
                </p>
                <p className="product-review-body">{item.content}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      <ProductReportModal
        isOpen={productReportModal}
        toggle={() => setProductReportModal((prev) => !prev)}
        product={{
          url: '',
          title: product?.goods_name || '',
          image: product?.goods_img || '',
        }}
      />
    </div>
  );
}
