import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'reactstrap';
import { IoClose } from 'react-icons/io5';
import CustomSelect from '../../../Inputs/CustomSelect/CustomSelect';
import { formatDateSpanish, parseDate } from '../../../../util/date';
import { CustomToast } from '../../../Notifications/CustomToast';
import { itemExistsInCart } from '../../../../util/cart';
import { useAuth } from '../../../../hooks/useAuth';
import FirebaseService from '../../../../services/firebase.service';
import {
  getWeightFromProductDetails,
  getWeigthFromProductDimensions,
} from '../../../../util/amazon';
import { findHistoryItem, getMaxWeight } from '../../../../util/array';
import { calculateAmazonPrice } from '../../../../util/price';
import AmazonService from '../../../../services/amazon.service';
import ModalLoader from '../../../Loaders/ModalLoader/ModalLoader';
import { MAX_PRODUCT_PRICE } from '../../../../contants';

const AmazonAddModal = ({
  isOpen,
  toggle,
  productDefault,
  trips = [],
  drivers = [],
  productHistory = [],
  categoryWeights = [],
  colorOptions = [],
  sizeOptions = [],
  setProductReportModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingCart, setLoadingCart] = useState(false);
  const [product, setProduct] = useState(null);
  const [color, setColor] = useState(null);
  const [size, setSize] = useState(null);
  const [travelerSelect, setTravelerSelect] = useState(null);
  const [weightProduct, setWeigthProduct] = useState(null);

  const { profile, cart, setCart } = useAuth();
  const amazonService = AmazonService.getInstance();
  const firebaseService = FirebaseService.getInstance();

  useEffect(() => {
    if (product === null && productDefault) {
      setProduct(productDefault);
      if (colorOptions?.length > 0) {
        const defaultColor = colorOptions.find(
          (item) => item.asin === productDefault.asin,
        );
        if (defaultColor)
          setColor({
            value: defaultColor.asin,
            label: defaultColor.value,
          });
      }
      if (sizeOptions?.length > 0) {
        const defaultSize = sizeOptions.find(
          (item) => item.asin === productDefault.asin,
        );
        if (defaultSize)
          setSize({
            value: defaultSize.asin,
            label: defaultSize.value,
          });
        if (
          sizeOptions?.length > 0 &&
          colorOptions?.length > 0 &&
          defaultSize &&
          !color
        ) {
          // Find the first matching size and color with the same asin
          const matchingSize = sizeOptions.find((size) =>
            colorOptions.some((color) => color.asin === size.asin),
          );
          // Find the corresponding color label for the matching size asin
          const matchingColor = colorOptions.find(
            (color) => color.asin === matchingSize?.asin,
          );
          // Set defaultColor with the asin from size and label from color
          if (matchingSize && matchingColor)
            setColor({
              value: defaultSize.asin,
              label: matchingColor.value,
            });
        }
      }
    }
  }, [product, productDefault, sizeOptions, colorOptions]);

  useEffect(() => {
    const fetchData = async () => {
      if (!color) return;
      try {
        setLoading(true);
        const searchResponse = await amazonService.search(color.value);
        const detailResponse = await amazonService.detail(color.value);
        setProduct(
          {
            ...searchResponse.data.products[0],
            ...detailResponse.data,
          } ?? null,
        );
      } catch {
        return null;
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [color]);

  useEffect(() => {
    const fetchData = async () => {
      if (!size) return;
      try {
        setLoading(true);
        const searchResponse = await amazonService.search(size.value);
        const detailResponse = await amazonService.detail(size.value);
        setProduct(
          {
            ...searchResponse.data.products[0],
            ...detailResponse.data,
          } ?? null,
        );
      } catch {
        return null;
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [size]);

  const salePrice = useMemo(() => {
    if (!product) return null;
    const asinProduct = product?.asin;
    const categoryList =
      product?.category_path?.map((value) => value.id?.toLowerCase()) ?? [];
    let price = parseFloat(product?.product_price?.replace(/[^0-9.-]+/g, ''));
    // History details
    const historyItem = findHistoryItem(productHistory, asinProduct);
    let historyWeight = historyItem?.currentWeigth ?? null;
    let historyPrice = historyItem?.price;
    if (
      (!price && !historyPrice) ||
      (price && parseFloat(price) > MAX_PRODUCT_PRICE) ||
      (historyPrice && parseFloat(historyPrice) > MAX_PRODUCT_PRICE)
    )
      return null;
    if (historyPrice) price = parseFloat(historyPrice);
    // Amazon details
    let weigthAmazon = getWeightFromProductDetails(product);
    const shippingAmazon = 0;
    // Dimension details
    let weigthDimension = getWeigthFromProductDimensions(product);
    // Default weight
    let weightDefault = getMaxWeight(categoryWeights, categoryList);
    if (historyWeight) {
      if (parseFloat(historyWeight) > 15) return null;
      if (parseFloat(historyWeight) < 0.25) historyWeight = 0.25;
      setWeigthProduct(parseFloat(historyWeight));
      return calculateAmazonPrice(
        price,
        parseFloat(historyWeight) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    if (weigthAmazon) {
      if (parseFloat(weigthAmazon) > 15) return null;
      if (parseFloat(weigthAmazon) < 0.25) weigthAmazon = 0.25;
      setWeigthProduct(parseFloat(weigthAmazon));
      return calculateAmazonPrice(
        price,
        parseFloat(weigthAmazon) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    if (weigthDimension) {
      if (parseFloat(weigthDimension) > 15) return null;
      if (parseFloat(weigthDimension) < 0.25) weigthDimension = 0.25;
      setWeigthProduct(parseFloat(weigthDimension));
      return calculateAmazonPrice(
        price,
        parseFloat(weigthDimension) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    if (weightDefault) {
      if (parseFloat(weightDefault) > 15) return null;
      if (parseFloat(weightDefault) < 0.25) weightDefault = 0.25;
      setWeigthProduct(parseFloat(weightDefault));
      return calculateAmazonPrice(
        price,
        parseFloat(weightDefault) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    return null;
  }, [product, productHistory, categoryWeights]);

  const originalPrice = useMemo(() => {
    if (!product) return null;
    const asinProduct = product?.asin;
    const categoryList =
      product?.category_path?.map((value) => value.id?.toLowerCase()) ?? [];
    let price = parseFloat(
      product?.product_original_price?.replace(/[^0-9.-]+/g, ''),
    );
    // History details
    const historyItem = findHistoryItem(productHistory, asinProduct);
    let historyWeight = historyItem?.currentWeigth ?? '';
    let historyPrice = historyItem?.price;
    if (
      (!price && !historyPrice) ||
      (price && parseFloat(price) > MAX_PRODUCT_PRICE) ||
      (historyPrice && parseFloat(historyPrice) > MAX_PRODUCT_PRICE)
    )
      return null;
    if (historyPrice) price = parseFloat(historyPrice);
    // Amazon details
    let weigthAmazon = getWeightFromProductDetails(product);
    const shippingAmazon = 0;
    // Dimension details
    let weigthDimension = getWeigthFromProductDimensions(product);
    // Default weight
    let weightDefault = getMaxWeight(categoryWeights, categoryList);
    if (historyWeight) {
      if (parseFloat(historyWeight) > 15) return null;
      if (parseFloat(historyWeight) < 0.25) historyWeight = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(historyWeight) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    if (weigthAmazon) {
      if (parseFloat(weigthAmazon) > 15) return null;
      if (parseFloat(weigthAmazon) < 0.25) weigthAmazon = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(weigthAmazon) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    if (weigthDimension) {
      if (parseFloat(weigthDimension) > 15) return null;
      if (parseFloat(weigthDimension) < 0.25) weigthDimension = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(weigthDimension) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    if (weightDefault) {
      if (parseFloat(weightDefault) > 15) return null;
      if (parseFloat(weightDefault) < 0.25) weightDefault = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(weightDefault) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    return null;
  }, [product, productHistory, categoryWeights]);

  const estimateDeliveryDate = useMemo(() => {
    let dateAmazon =
      product?.buybox_winner?.fulfillment?.standard_delivery?.date || '';
    if (!dateAmazon) {
      const nowDate = new Date();
      nowDate.setDate(nowDate.getDate() + 15);
      return nowDate;
    }
    const date = parseDate(dateAmazon);
    const dateOrder = [...date].reverse()[0];
    const filterTrips = trips.filter(
      (value) => value?.date > date?.[0]?.toISOString(),
    );
    const dateOptimization = filterTrips.find((info) => {
      const userInfo = drivers.find((user) => user?.uid === info.travelerUid);
      if (!userInfo) return false;
      const dayBeforeTravel = userInfo.dayBeforeTravel || 3;
      const dayTravel = new Date(info.date);
      dayTravel.setDate(dayTravel.getDate() - dayBeforeTravel);
      if (dateOrder < dayTravel) return true;
      return false;
    });
    if (!dateOptimization) {
      const currentDate = new Date(dateOrder);
      currentDate.setDate(currentDate.getDate() + 15);
      return currentDate;
    }
    setTravelerSelect(dateOptimization);
    const dateTravel = new Date(dateOptimization.date);
    const userInfo = drivers.find(
      (user) => user?.uid === dateOptimization.travelerUid,
    );
    const daysAfterTravel = userInfo.dayAfterTravel || 3;
    dateTravel.setDate(dateTravel.getDate() + parseFloat(daysAfterTravel));
    return dateTravel;
  }, [product, trips, drivers]);

  const handleAddToCart = async () => {
    if (!product) return;
    try {
      setLoadingCart(true);
      if (itemExistsInCart(cart.items, product)) {
        CustomToast('warning', 'Producto ya se encuentra en el carrito');
        return;
      }
      const productToAdd = {
        ...product,
        weigthAmazon: weightProduct ?? null,
        priceCalculate: salePrice,
        estimateDeliveryDate: estimateDeliveryDate?.toISOString(),
        travelerSelect,
        color,
        size,
        ttl: parseFloat(process.env.REACT_APP_CART_TTL),
        createdAt: Date.now(),
      };
      const items = [...cart.items, productToAdd];
      if (profile)
        await firebaseService.updateCart(profile, { ...cart, items });
      setCart({ ...cart, items });
      CustomToast('success', 'Producto agregado al carrito');
      toggle();
    } catch {
      CustomToast('error', 'Error al agregar producto al carrito');
    } finally {
      setLoadingCart(false);
    }
  };

  return (
    <Modal id="productModal" isOpen={isOpen} toggle={toggle} size="lg" centered>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          marginBottom: '1rem',
        }}
      >
        <IoClose onClick={toggle} />
      </div>
      {loading ? (
        <ModalLoader />
      ) : (
        <>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <img
              src={product?.product_photo}
              alt="Foto"
              style={{ width: '200px', height: '200px', objectFit: 'contain' }}
            />
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
            >
              <p style={{ fontSize: '20px', fontWeight: '700' }}>
                {product?.product_title}
              </p>
              {estimateDeliveryDate && (
                <p className="productDeliveryDate">
                  Fecha Estimada de entrega:{' '}
                  <b>{formatDateSpanish(estimateDeliveryDate)}</b>
                </p>
              )}
              {salePrice && (
                <p className="productPrice">
                  {originalPrice > salePrice && (
                    <span className="productRRP">
                      <span className="productPriceSymbol">$</span>
                      <span className="productPriceWhole">
                        {originalPrice.toFixed(2)}
                      </span>
                    </span>
                  )}
                  <span className="productDiscountedPrice">
                    <span className="productPriceSymbol">$</span>
                    <span className="productPriceWhole">
                      {salePrice.toFixed(2)}
                    </span>
                  </span>
                </p>
              )}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              justifyContent: 'space-between',
              marginTop: '1rem',
            }}
          >
            {colorOptions && colorOptions?.length > 0 && (
              <div
                className="section-container"
                onClick={(e) => e.stopPropagation()}
              >
                <p className="section-title">Colores:</p>
                <CustomSelect
                  placeholder="Selecciona un color"
                  value={color}
                  setValue={setColor}
                  options={colorOptions.map((item) => {
                    return {
                      value: item.asin,
                      label: item.value,
                    };
                  })}
                />
              </div>
            )}
            {sizeOptions && sizeOptions?.length > 0 && (
              <div
                className="section-container"
                onClick={(e) => e.stopPropagation()}
              >
                <p className="section-title">Tallas/Capacidades/Tamaños:</p>
                <CustomSelect
                  placeholder="Selecciona una talla/capacidad/tamaño"
                  value={size}
                  setValue={setSize}
                  options={sizeOptions.map((item) => {
                    return {
                      value: item.asin,
                      label: item.value,
                    };
                  })}
                />
              </div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '1rem',
              gap: '1rem',
            }}
          >
            <Button className="secondaryBtn" onClick={toggle}>
              Cancelar
            </Button>
            <Button
              className="primaryBtn"
              onClick={
                salePrice ? handleAddToCart : () => setProductReportModal(true)
              }
            >
              {salePrice
                ? loadingCart
                  ? 'Agregando al carrito...'
                  : 'Agregar al carrito'
                : 'Solicitar cotizacion'}
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default AmazonAddModal;
