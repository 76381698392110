import {
  query,
  getDocs,
  collection,
  where,
  doc,
  updateDoc,
  orderBy,
  addDoc,
  getDoc,
} from 'firebase/firestore';
import { db } from '../util/firebase';
import { v4 as uuidv4 } from 'uuid';

export default class FirebaseService {
  static instance;

  static getInstance() {
    FirebaseService.instance ??= new FirebaseService();
    return FirebaseService.instance;
  }

  async findProfile(uid) {
    const q = query(collection(db, 'Users'), where('uid', '==', uid));
    const doc = await getDocs(q);
    return doc.docs.length > 0 ? doc.docs[0].data() : null;
  }

  async findMyOrders(uid) {
    const q = query(
      collection(db, 'Orders'),
      where('customerUid', '==', uid),
      orderBy('dateCreated', 'desc'),
    );
    const doc = await getDocs(q);
    return doc.docs.map((value) => {
      return {
        ...value.data(),
        id: value.id,
      };
    });
  }

  async findDiscountCodes() {
    const ref = collection(db, 'DiscountCode');
    const q = query(ref);
    const doc = await getDocs(q);
    return doc.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
  }

  async findDrivers() {
    const q = query(collection(db, 'Users'));
    const doc = await getDocs(q);
    return doc.docs
      .map((value) => {
        const data = value.data();
        return data;
      })
      .filter((info) => info.type === 'driver');
  }

  async findUsers() {
    const q = query(collection(db, 'Users'));
    const doc = await getDocs(q);
    return doc.docs.map((value) => {
      const data = value.data();
      return data;
    });
  }

  async findOrCreateCart(profile) {
    const ref = query(
      collection(db, 'PasseioCarts'),
      where('ownerUid', '==', profile?.uid),
    );
    let docSnapshot = await getDocs(ref);
    let carts = docSnapshot.docs.map((value) => value.data());
    if (carts.length === 0) {
      const newCartRef = collection(db, 'PasseioCarts');
      await addDoc(newCartRef, {
        id: uuidv4(),
        ownerUid: profile.uid,
        items: [],
      });
      docSnapshot = await getDocs(ref);
      carts = docSnapshot.docs.map((value) => value.data());
    }
    if (!carts[0]?.id) {
      const cartDoc = doc(db, 'PasseioCarts', docSnapshot.docs[0].id);
      await updateDoc(cartDoc, {
        id: uuidv4(),
        ...carts[0],
      });
      docSnapshot = await getDocs(ref);
      carts = docSnapshot.docs.map((value) => value.data());
    }
    return carts;
  }

  async updateCartMigration(migrated) {
    const ref = collection(db, 'PasseioCarts');
    const q = query(ref);
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(async (doc) => {
      const docRef = doc.ref;
      await updateDoc(docRef, { migrated });
    });
  }

  async updateCart(profile, newCart) {
    const ref = query(
      collection(db, 'PasseioCarts'),
      where('ownerUid', '==', profile?.uid),
    );
    const findCarts = await getDocs(ref);
    findCarts.forEach(async (cart) => {
      const getCart = doc(db, 'PasseioCarts', cart.id);
      await updateDoc(getCart, newCart);
    });
  }

  async updateUser(uid, data) {
    const ref = collection(db, 'Users');
    const q = query(ref, where('uid', '==', uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(async (doc) => {
      const docRef = doc.ref;
      await updateDoc(docRef, data);
    });
  }

  async findTrips() {
    const q = query(
      collection(db, 'Trips'),
      where('date', '>', new Date().toISOString()),
      orderBy('date'),
    );
    const doc = await getDocs(q);
    return doc.docs.map((value) => {
      return value.data();
    });
  }

  async findRecommendedProducts() {
    const ref = collection(db, 'Recomendations');
    const q = query(ref);
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  }

  async findCategoryWeights() {
    const ref = collection(db, 'CategoryWeight');
    const q = query(ref);
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  }

  async findActiveCategoryWeights() {
    const ref = collection(db, 'CategoryWeight');
    const q = query(ref, where('status', '==', true));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  }

  async findOneCategoryWeight(id) {
    const ref = collection(db, 'CategoryWeight');
    const q = query(ref, where('category_id', '==', id));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  }

  async updateCategoryWeight(id, data) {
    const ref = collection(db, 'CategoryWeight');
    const q = query(ref, where('category_id', '==', id));
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(async (doc) => {
      const docRef = doc.ref;
      await updateDoc(docRef, data);
    });
  }

  async createCategoryWeight(data) {
    const ref = collection(db, 'CategoryWeight');
    await addDoc(ref, data);
  }

  async validateCategoryWeight(id) {
    const ref = collection(db, 'CategoryWeight');
    const q = query(ref, where('category_id', '==', id));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.length === 0;
  }

  async findRecommendedCategories() {
    const ref = collection(db, 'RecommendedWishlistCategories');
    const q = query(ref);
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  }

  async createHistoryProduct(data) {
    const ref = collection(db, 'historyProduct');
    await addDoc(ref, data);
  }

  async updateHistoryProduct(asin, data) {
    const ref = collection(db, 'historyProduct');
    const q = query(ref, where('asin', '==', asin));
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(async (doc) => {
      const docRef = doc.ref;
      await updateDoc(docRef, data);
    });
  }

  async validateHistoryProduct(asin) {
    const ref = collection(db, 'historyProduct');
    const q = query(ref, where('asin', '==', asin));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.length === 0;
  }

  async findHistoryProducts() {
    const ref = collection(db, 'historyProduct');
    const q = query(ref);
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  }

  async findOneHistoryProduct(uid) {
    const q = doc(db, 'historyProduct', uid);
    const data = await getDoc(q);
    return data.data();
  }

  async findCommonSearches() {
    const ref = collection(db, 'common-search');
    const q = query(ref);
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  }

  async findOneCommonSearch(id) {
    const ref = collection(db, 'common-search');
    const q = query(ref, where('id', '==', id));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  }

  async updateCommonSearch(id, data) {
    const ref = collection(db, 'common-search');
    const q = query(ref, where('id', '==', id));
    const querySnapshot = await getDocs(q);
    querySnapshot.docs.forEach(async (doc) => {
      const docRef = doc.ref;
      await updateDoc(docRef, data);
    });
  }

  async createCommonSearch(data) {
    const ref = collection(db, 'common-search');
    await addDoc(ref, data);
  }
}
