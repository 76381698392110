import { useMemo, useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { calculateSheinPrice } from '../../../../util/price';
import { formatDateSpanish } from '../../../../util/date';
import ProductReportModal from '../../Modals/ReportModal/ProductReportModal';
import SheinService from '../../../../services/shein.service';
import TextLoader from '../../../Loaders/TextLoader/TextLoader';
import { useNavigate } from 'react-router-dom';
import StarRating from '../../../Reviews/StarRating/StarRating';
import SheinAddModal from '../../Modals/Shein/SheinAddModal';
import { MAX_PRODUCT_PRICE } from '../../../../contants';

export const SheinProductCard = ({ productDefault }) => {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [productReportModal, setProductReportModal] = useState(false);
  const [productAddModal, setProductAddModal] = useState(false);

  const navigate = useNavigate();
  const sheinService = SheinService.getInstance();

  useEffect(() => {
    const fetchData = async () => {
      if (!productDefault || product) return;
      try {
        let response = await sheinService.detail(productDefault.goods_id);
        if (!response.info)
          response = await sheinService.legacyDetail(productDefault.goods_id);
        let extraInfo = await sheinService.extraInfo(productDefault.goods_id);
        if (!extraInfo.info)
          extraInfo = await sheinService.legacyExtraInfo(
            productDefault.goods_id,
          );
        setProduct(
          {
            ...response.info,
            ...extraInfo.info,
          } ?? null,
        );
      } catch {
        return null;
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [productDefault, product]);

  const colorOptions = useMemo(() => {
    if (!product) return null;
    return product?.related_color_goods;
  }, [product]);

  const sizeOptions = useMemo(() => {
    if (!product) return null;
    return product?.multiLevelSaleAttribute;
  });

  const salePrice = useMemo(() => {
    if (!product) return null;
    let price = parseFloat(product.sale_price?.amount);
    if (!price || price > MAX_PRODUCT_PRICE) return null;
    return calculateSheinPrice(price);
  }, [product]);

  const originalPrice = useMemo(() => {
    if (!product) return null;
    let price = parseFloat(product.retail_price?.amount);
    if (!price || price > MAX_PRODUCT_PRICE) return null;
    return calculateSheinPrice(price);
  }, [product]);

  const estimateDeliveryDate = useMemo(() => {
    const nowDate = new Date();
    nowDate.setDate(nowDate.getDate() + 20);
    return nowDate;
  }, [product]);

  return (
    <div
      className="product"
      onClick={() => navigate(`/detail/${productDefault.goods_id}`)}
    >
      <div className="productImageContainer">
        <img
          alt="productImage"
          src={productDefault.goods_img}
          className="productImage"
        />
      </div>
      <div className="productContent">
        <p className="productName">{productDefault.goods_name}</p>

        {product?.comments_overview?.comment_rank_average && (
          <div className="rating-container">
            <StarRating
              rating={product.comments_overview?.comment_rank_average}
              style={{ margin: 0, padding: 0 }}
            />
          </div>
        )}

        {estimateDeliveryDate && (
          <p className="productDeliveryDate">
            Fecha Estimada de entrega:{' '}
            <span className="bold-text">
              {formatDateSpanish(estimateDeliveryDate)}
            </span>
          </p>
        )}
        {loading ? (
          <TextLoader
            style={{
              alignSelf: 'center',
              width: '100%',
              padding: '0.5rem 2rem',
              fontSize: '16px',
            }}
            texts={['Calculando precio todo incluido']}
          />
        ) : (
          <>
            {salePrice ? (
              <>
                <p className="productPrice">
                  {originalPrice > salePrice && (
                    <span className="productRRP">
                      <span className="productPriceSymbol">$</span>
                      <span className="productPriceWhole">
                        {originalPrice.toFixed(2)}
                      </span>
                    </span>
                  )}
                  <span className="productDiscountedPrice">
                    <span className="productPriceSymbol">$</span>
                    <span className="productPriceWhole">
                      {salePrice.toFixed(2)}
                    </span>
                  </span>
                </p>
                <p className="priceMessage">(Precio todo incluido)</p>

                <Button
                  className="primaryBtn"
                  onClick={(e) => {
                    e.stopPropagation();
                    setProductAddModal(true);
                  }}
                >
                  Agregar
                </Button>
              </>
            ) : (
              <Button
                className="primaryBtn"
                style={{ marginTop: '0.5rem' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setProductReportModal(true);
                }}
              >
                Solicitar cotización
              </Button>
            )}
          </>
        )}
      </div>
      <ProductReportModal
        showReport={productReportModal}
        toggle={() => setProductReportModal((prev) => !prev)}
        product={{
          url: '',
          title: product?.goods_name || '',
          image: product?.goods_img || '',
        }}
      />
      <SheinAddModal
        isOpen={productAddModal}
        toggle={() => setProductAddModal((prev) => !prev)}
        productDefault={product}
        colorOptions={colorOptions}
        sizeOptions={sizeOptions}
        setProductReportModal={setProductReportModal}
      />
    </div>
  );
};
