import React, { useEffect, useMemo, useState } from 'react';
import PageLoader from '../../../Loaders/PageLoader/PageLoader';
import { CustomToast } from '../../../Notifications/CustomToast';
import '../../../../assets/css/ProductDetail.css';
import { formatDateSpanish, parseDate } from '../../../../util/date';
import { calculateAmazonPrice } from '../../../../util/price';
import { findHistoryItem, getMaxWeight } from '../../../../util/array';
import {
  getWeightFromProductDetails,
  getWeigthFromProductDimensions,
} from '../../../../util/amazon';
import { cancelAllRequests } from '../../../../util/axios-req';
import ImageViewer from '../../../Images/ImageViewer';
import { Button } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import CustomSelect from '../../../Inputs/CustomSelect/CustomSelect';
import StarRating from '../../../Reviews/StarRating/StarRating';
import { AmazonProductCard } from '../../Card/Amazon/AmazonProductCard';
import AmazonService from '../../../../services/amazon.service';
import FirebaseService from '../../../../services/firebase.service';
import { itemExistsInCart } from '../../../../util/cart';
import { useAuth } from '../../../../hooks/useAuth';
import ProductReportModal from '../../Modals/ReportModal/ProductReportModal';
import { limitWords } from '../../../../util/string';
import { MAX_PRODUCT_PRICE } from '../../../../contants';

export default function AmazonDetail({ asin }) {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(null);
  const [recommendations, setRecommendations] = useState([]);
  const [color, setColor] = useState(null);
  const [size, setSize] = useState(null);
  const [travelerSelect, setTravelerSelect] = useState(null);
  const [weightProduct, setWeigthProduct] = useState(null);
  const [drivers, setDrivers] = useState([]);
  const [trips, setTrips] = useState([]);
  const [productHistory, setProductHistory] = useState([]);
  const [categoryWeights, setCategoryWeights] = useState([]);
  const [productReportModal, setProductReportModal] = useState(false);

  const { profile, cart, setCart } = useAuth();
  const navigate = useNavigate();
  const amazonService = AmazonService.getInstance();
  const firebaseService = FirebaseService.getInstance();

  const colorOptions = useMemo(() => {
    if (!product) return null;
    return product?.product_variations?.color?.filter(
      (item) => item.is_available,
    );
  }, [product]);

  const sizeOptions = useMemo(() => {
    if (!product) return null;
    return product?.product_variations?.size?.filter(
      (item) => item.is_available,
    );
  }, [product]);

  useEffect(() => {
    const fetchData = async () => {
      if (loading || !asin || product?.asin === asin) return;
      try {
        resetValues();
        setLoading(true);
        const productPromise = searchProduct();
        const driversPromise = searchDrivers();
        const tripsPromise = searchTrips();
        const productHistoryPromise = searchProductHistory();
        const categoryWeightsPromise = searchCategoryWeights();
        await Promise.all([
          productPromise,
          driversPromise,
          tripsPromise,
          productHistoryPromise,
          categoryWeightsPromise,
        ]);
      } catch {
        CustomToast('error', 'Error al buscar productos');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [asin]);

  useEffect(() => {
    if (loading || !product) return;
    console.debug(product);
    if (colorOptions?.length > 0) {
      const defaultColor = colorOptions.find(
        (item) => item.asin === product.asin,
      );
      if (defaultColor)
        setColor({
          value: defaultColor.asin,
          label: defaultColor.value,
        });
    }
    if (sizeOptions?.length > 0) {
      const defaultSize = sizeOptions.find(
        (item) => item.asin === product.asin,
      );
      if (defaultSize)
        setSize({
          value: defaultSize.asin,
          label: defaultSize.value,
        });
      if (
        sizeOptions?.length > 0 &&
        colorOptions?.length > 0 &&
        defaultSize &&
        !color
      ) {
        // Find the first matching size and color with the same asin
        const matchingSize = sizeOptions.find((size) =>
          colorOptions.some((color) => color.asin === size.asin),
        );
        // Find the corresponding color label for the matching size asin
        const matchingColor = colorOptions.find(
          (color) => color.asin === matchingSize?.asin,
        );
        // Set defaultColor with the asin from size and label from color
        if (matchingSize && matchingColor)
          setColor({
            value: defaultSize.asin,
            label: matchingColor.value,
          });
      }
    }
  }, [loading, product, colorOptions, sizeOptions]);

  useEffect(() => {
    if (!color || color?.value === product?.asin) return;
    navigate(`/detail/${color.value}`, { replace: true });
  }, [color]);

  useEffect(() => {
    if (!size || size?.value === product?.asin) return;
    navigate(`/detail/${size.value}`, { replace: true });
  }, [size]);

  useEffect(() => {
    if (!product || recommendations.length > 0) return;
    searchRecomendations();
  }, [product, recommendations]);

  const resetValues = async () => {
    cancelAllRequests();
    setProduct(null);
    setRecommendations([]);
    setColor(null);
    setSize(null);
  };

  const searchProduct = async () => {
    try {
      const searchResponse = await amazonService.search(asin);
      const detailResponse = await amazonService.detail(asin);
      const reviewsResponse = await amazonService.reviews(asin);
      setProduct(
        {
          ...searchResponse.data.products[0],
          ...detailResponse.data,
          ...reviewsResponse.data,
        } ?? null,
      );
    } catch {
      CustomToast('error', 'Error al buscar producto');
    }
  };

  const searchRecomendations = async () => {
    if (!product?.product_title) return;
    try {
      const query = limitWords(product.product_title, 5);
      const response = await amazonService.search(query);
      if (response.data.products.length > 0)
        setRecommendations(response.data.products.slice(0, 10));
    } catch {
      CustomToast('error', 'Error al buscar recomendaciones');
    }
  };

  const searchDrivers = async () => {
    try {
      const response = await firebaseService.findDrivers();
      if (response.length > 0) setDrivers(response);
    } catch {
      CustomToast('error', 'Error al buscar conductores');
    }
  };

  const searchTrips = async () => {
    try {
      const response = await firebaseService.findTrips();
      if (response.length > 0) setTrips(response);
    } catch {
      CustomToast('error', 'Error al buscar viajes');
    }
  };

  const searchCategoryWeights = async () => {
    try {
      const response = await firebaseService.findActiveCategoryWeights();
      if (response.length > 0) setCategoryWeights(response);
    } catch {
      CustomToast('error', 'Error al buscar pesos de categorías');
    }
  };

  const searchProductHistory = async () => {
    try {
      const response = await firebaseService.findHistoryProducts();
      if (response.length > 0) setProductHistory(response);
    } catch {
      CustomToast('error', 'Error al buscar historial de productos');
    }
  };

  const handleAddToCart = async () => {
    if (!product) return;
    if (colorOptions && colorOptions?.length > 0 && !color) {
      CustomToast('warning', 'Por favor selecciona un color');
      return;
    }
    if (sizeOptions && sizeOptions?.length > 0 && !size) {
      CustomToast('warning', 'Por favor selecciona una talla/capacidad/tamaño');
      return;
    }
    try {
      setLoading(true);
      if (itemExistsInCart(cart.items, product)) {
        CustomToast('warning', 'Producto ya se encuentra en el carrito');
        return;
      }
      const productToAdd = {
        ...product,
        priceCalculate: salePrice,
        weigthAmazon: weightProduct ?? null,
        estimateDeliveryDate: estimateDeliveryDate?.toISOString(),
        travelerSelect,
        color,
        size,
        ttl: parseFloat(process.env.REACT_APP_CART_TTL),
        createdAt: Date.now(),
      };
      const items = [...cart.items, productToAdd];
      if (profile)
        await firebaseService.updateCart(profile, { ...cart, items });
      setCart({ ...cart, items });
      CustomToast('success', 'Producto agregado al carrito');
    } catch {
      CustomToast('error', 'Error al agregar producto al carrito');
    } finally {
      setLoading(false);
    }
  };

  const handlePurchase = async () => {
    handleAddToCart();
    navigate('/checkout');
  };

  const salePrice = useMemo(() => {
    if (!product) return null;
    const asinProduct = product?.asin;
    const categoryList =
      product?.category_path?.map((value) => value.id?.toLowerCase()) ?? [];
    let price = parseFloat(product?.product_price?.replace(/[^0-9.-]+/g, ''));
    // History details
    const historyItem = findHistoryItem(productHistory, asinProduct);
    let historyWeight = historyItem?.currentWeigth ?? null;
    let historyPrice = historyItem?.price;
    if (
      (!price && !historyPrice) ||
      (price && parseFloat(price) > MAX_PRODUCT_PRICE) ||
      (historyPrice && parseFloat(historyPrice) > MAX_PRODUCT_PRICE)
    )
      return null;
    if (historyPrice) price = parseFloat(historyPrice);
    // Amazon details
    let weigthAmazon = getWeightFromProductDetails(product);
    const shippingAmazon = 0;
    // Dimension details
    let weigthDimension = getWeigthFromProductDimensions(product);
    // Default weight
    let weightDefault = getMaxWeight(categoryWeights, categoryList);
    console.dir({
      historyWeight,
      weigthAmazon,
      weigthDimension,
      weightDefault,
    });
    if (historyWeight) {
      if (parseFloat(historyWeight) > 15) return null;
      if (parseFloat(historyWeight) < 0.25) historyWeight = 0.25;
      setWeigthProduct(parseFloat(historyWeight));
      return calculateAmazonPrice(
        price,
        parseFloat(historyWeight) ?? 0,
        shippingAmazon ?? 0,
        true,
      );
    }
    if (weigthAmazon) {
      if (parseFloat(weigthAmazon) > 15) return null;
      if (parseFloat(weigthAmazon) < 0.25) weigthAmazon = 0.25;
      setWeigthProduct(parseFloat(weigthAmazon));
      return calculateAmazonPrice(
        price,
        parseFloat(weigthAmazon) ?? 0,
        shippingAmazon ?? 0,
        true,
      );
    }
    if (weigthDimension) {
      if (parseFloat(weigthDimension) > 15) return null;
      if (parseFloat(weigthDimension) < 0.25) weigthDimension = 0.25;
      setWeigthProduct(parseFloat(weigthDimension));
      return calculateAmazonPrice(
        price,
        parseFloat(weigthDimension) ?? 0,
        shippingAmazon ?? 0,
        true,
      );
    }
    if (weightDefault) {
      if (parseFloat(weightDefault) > 15) return null;
      if (parseFloat(weightDefault) < 0.25) weightDefault = 0.25;
      setWeigthProduct(parseFloat(weightDefault));
      return calculateAmazonPrice(
        price,
        parseFloat(weightDefault) ?? 0,
        shippingAmazon ?? 0,
        true,
      );
    }
    return null;
  }, [product, productHistory, categoryWeights]);

  const originalPrice = useMemo(() => {
    if (!product) return null;
    const asinProduct = product?.asin;
    const categoryList =
      product?.category_path?.map((value) => value.id?.toLowerCase()) ?? [];
    let price = parseFloat(
      product?.product_original_price?.replace(/[^0-9.-]+/g, ''),
    );
    // History details
    const historyItem = findHistoryItem(productHistory, asinProduct);
    let historyWeight = historyItem?.currentWeigth ?? null;
    let historyPrice = historyItem?.price;
    if (
      (!price && !historyPrice) ||
      (price && parseFloat(price) > MAX_PRODUCT_PRICE) ||
      (historyPrice && parseFloat(historyPrice) > MAX_PRODUCT_PRICE)
    )
      return null;
    if (historyPrice) price = parseFloat(historyPrice);
    // Amazon details
    let weigthAmazon = getWeightFromProductDetails(product);
    const shippingAmazon = 0;
    // Dimension details
    let weigthDimension = getWeigthFromProductDimensions(product);
    // Default weight
    let weightDefault = getMaxWeight(categoryWeights, categoryList);
    if (historyWeight) {
      if (parseFloat(historyWeight) > 15) return null;
      if (parseFloat(historyWeight) < 0.25) historyWeight = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(historyWeight) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    if (weigthAmazon) {
      if (parseFloat(weigthAmazon) > 15) return null;
      if (parseFloat(weigthAmazon) < 0.25) weigthAmazon = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(weigthAmazon) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    if (weigthDimension) {
      if (parseFloat(weigthDimension) > 15) return null;
      if (parseFloat(weigthDimension) < 0.25) weigthDimension = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(weigthDimension) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    if (weightDefault) {
      if (parseFloat(weightDefault) > 15) return null;
      if (parseFloat(weightDefault) < 0.25) weightDefault = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(weightDefault) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    return null;
  }, [product, productHistory, categoryWeights]);

  const estimateDeliveryDate = useMemo(() => {
    let dateAmazon =
      product?.buybox_winner?.fulfillment?.standard_delivery?.date || '';
    if (!dateAmazon) {
      const nowDate = new Date();
      nowDate.setDate(nowDate.getDate() + 15);
      return nowDate;
    }
    const date = parseDate(dateAmazon);
    const dateOrder = [...date].reverse()[0];
    const filterTrips = trips.filter(
      (value) => value?.date > date?.[0]?.toISOString(),
    );
    const dateOptimization = filterTrips.find((info) => {
      const userInfo = drivers.find((user) => user?.uid === info.travelerUid);
      if (!userInfo) return false;
      const dayBeforeTravel = userInfo.dayBeforeTravel || 3;
      const dayTravel = new Date(info.date);
      dayTravel.setDate(dayTravel.getDate() - dayBeforeTravel);
      if (dateOrder < dayTravel) return true;
      return false;
    });
    if (!dateOptimization) {
      const currentDate = new Date(dateOrder);
      currentDate.setDate(currentDate.getDate() + 15);
      return currentDate;
    }
    setTravelerSelect(dateOptimization);
    const dateTravel = new Date(dateOptimization.date);
    const userInfo = drivers.find(
      (user) => user?.uid === dateOptimization.travelerUid,
    );
    const daysAfterTravel = userInfo.dayAfterTravel || 3;
    dateTravel.setDate(dateTravel.getDate() + parseFloat(daysAfterTravel));
    return dateTravel;
  }, [product, trips, drivers]);

  const discountPercentage = useMemo(() => {
    if (!salePrice || !originalPrice) return null;
    return ((1 - salePrice / originalPrice) * 100).toFixed(2);
  }, [salePrice, originalPrice]);

  if (loading || !product) return <PageLoader />;

  return (
    <div className="detail-container">
      <div className="top-section">
        {product?.product_photos?.length > 0 && (
          <div className="image-gallery">
            <ImageViewer
              images={product?.product_photos.map((item, index) => {
                return {
                  src: item,
                  description: index,
                };
              })}
            />
          </div>
        )}

        <div className="product-details">
          <h1 className="product-title">{product?.product_title}</h1>
          {product?.product_star_rating && (
            <div className="section-container">
              <StarRating rating={product.product_star_rating} />
            </div>
          )}
          <div className="price-container">
            <div className="price-section">
              {discountPercentage > 0 && (
                <span className="discount-percentage">
                  -{discountPercentage}%
                </span>
              )}
              {salePrice && (
                <span className="discount-price">${salePrice}</span>
              )}
            </div>
            {originalPrice && originalPrice !== salePrice && (
              <div className="price-section">
                <span className="original-price-title">Precio original: </span>
                <span className="original-price">${originalPrice}</span>
              </div>
            )}
          </div>

          {colorOptions && colorOptions?.length > 0 && (
            <div className="section-container">
              <p className="section-title">Colores:</p>
              <CustomSelect
                placeholder="Selecciona un color"
                value={color}
                setValue={setColor}
                options={colorOptions.map((item) => {
                  return {
                    value: item.asin,
                    label: item.value,
                  };
                })}
              />
            </div>
          )}

          {sizeOptions && sizeOptions?.length > 0 && (
            <div className="section-container">
              <p className="section-title">Tallas/Capacidades/Tamaños:</p>
              <CustomSelect
                placeholder="Selecciona una talla/capacidad/tamaño"
                value={size}
                setValue={setSize}
                options={sizeOptions.map((item) => {
                  return {
                    value: item.asin,
                    label: item.value,
                  };
                })}
              />
            </div>
          )}

          {product?.product_description && (
            <div className="section-container">
              <p className="section-title">Sobre este producto:</p>
              <p>{product?.product_description}</p>
            </div>
          )}
        </div>

        <div className="purchase-options">
          {salePrice ? (
            <>
              <p className="section-title">Comprar nuevo:</p>
              <h2>${salePrice}</h2>
              <p>En existencias</p>
              {estimateDeliveryDate && (
                <p className="delivery-date">
                  Fecha Estimada de entrega:{' '}
                  <b>{formatDateSpanish(estimateDeliveryDate)}</b>
                </p>
              )}
              <div className="purchase-buttons">
                <Button className="primaryBtn" onClick={handleAddToCart}>
                  Agregar a carrito
                </Button>
                <Button className="secondaryBtn" onClick={handlePurchase}>
                  Comprar ahora
                </Button>
              </div>
            </>
          ) : (
            <>
              <p className="section-title">Este producto no esta disponible</p>
              <Button
                className="primaryBtn"
                onClick={() => setProductReportModal(true)}
              >
                Solicitar cotizacion
              </Button>
            </>
          )}
        </div>
      </div>

      <div className="bottom-section">
        {product?.product_information &&
          Object.keys(product.product_information).length > 0 && (
            <div className="product-specifications">
              <p className="section-title">Detalles del producto:</p>
              {Object.entries(product.product_information).map(
                ([key, value], index) => (
                  <div key={index} className="product-specifications-item">
                    <p>{key}:</p>
                    <p>{value}</p>
                  </div>
                ),
              )}
            </div>
          )}

        {recommendations?.length > 0 && (
          <div className="product-recommendations">
            <p className="section-title">Recomendaciones:</p>
            <div className="recommendations-carousel">
              {recommendations.map((item, index) => (
                <div key={index} className="recommendation-item">
                  <AmazonProductCard
                    key={index}
                    productDefault={item}
                    trips={trips}
                    drivers={drivers}
                    productHistory={productHistory}
                    categoryWeights={categoryWeights}
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        {product?.reviews?.length > 0 && (
          <div className="product-reviews">
            <p className="section-title">Reseñas principales</p>
            {product?.reviews?.map((item, index) => (
              <div key={index} className="product-review-item">
                <div className="product-review-header">
                  <img
                    src={
                      item.review_author_avatar ??
                      `https://eu.ui-avatars.com/api/?name=${item.review_author}&size=250`
                    }
                    alt={`${item.review_author}'s profile`}
                    className="profile-image"
                  />
                  <Link to={item.review_link} rel="noreferrer" target="_blank">
                    {item.review_author}
                  </Link>
                </div>
                <div className="product-review-rating">
                  <StarRating rating={item.review_star_rating} />
                  <p style={{ fontWeight: 'bold' }}>{item.review_title}</p>
                </div>
                <p className="product-review-date">
                  {item.review_date}
                  {item.is_verified_purchase && (
                    <span>&nbsp;| Compra verificada</span>
                  )}
                </p>
                <p className="product-review-body">{item.review_comment}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      <ProductReportModal
        isOpen={productReportModal}
        toggle={() => setProductReportModal((prev) => !prev)}
        product={{
          url: product?.product_url || '',
          title: product?.product_title || '',
          image: product?.product_photo || '',
        }}
      />
    </div>
  );
}
