const debugLog = (formula, total, debug) => {
  if (debug) console.debug(`${formula} = ${total.toFixed(2)}`);
};

export const calculateAmazonPrice = (
  price,
  weight,
  shipping = 0,
  debug = false,
) => {
  const isCategorySpecial = price >= 200;
  const commission = isCategorySpecial ? 1.13 : 1;
  const total = (price + shipping) * 1.09 * commission + weight * 5.65 * 1.15;

  debugLog(
    `(((${price} + ${shipping}) * 1.09) * ${commission}) + (${weight} * 5.65 * 1.15)`,
    total,
    debug,
  );

  return parseFloat(total.toFixed(2));
};

export const calculateSheinPrice = (price, debug = false) => {
  const getMultiplier = (price) => {
    if (price <= 2) return 1.95;
    if (price <= 4) return 1.75;
    if (price <= 10) return 1.55;
    if (price <= 20) return 1.45;
    if (price <= 30) return 1.4;
    return 1.35;
  };

  const isCategorySpecial = price >= 200;
  const commission = isCategorySpecial ? 1.13 : 1;
  const multiplier = getMultiplier(price);
  const total = price * multiplier * commission;

  debugLog(`(${price} * ${multiplier}) * ${commission}`, total, debug);

  return parseFloat(total.toFixed(2));
};
