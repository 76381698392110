import axiosInstance from '../util/axios-req';

export default class AmazonService {
  static instance;

  static getInstance() {
    AmazonService.instance ??= new AmazonService();
    return AmazonService.instance;
  }

  async search(query, page = 1) {
    const response = await axiosInstance.get(`/amazon/search`, {
      params: {
        query,
        is_prime: true,
        page,
      },
    });
    return response.data;
  }

  async detail(asin) {
    const response = await axiosInstance.get(`/amazon/product-details`, {
      params: {
        asin,
      },
    });
    return response.data;
  }

  async deals(page = 1) {
    const response = await axiosInstance.get(`/amazon/deals`, {
      params: {
        page,
      },
    });
    return response.data;
  }

  async reviews(asin) {
    const response = await axiosInstance.get(`/amazon/product-reviews`, {
      params: {
        asin,
      },
    });
    return response.data;
  }
}
