import React, { createContext, useState, useEffect } from 'react';
import { auth } from '../util/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import PageLoader from '../components/Loaders/PageLoader/PageLoader';
import { DEFAULT_CART } from '../contants';
import { signOut } from 'firebase/auth';
import { mergeCartItems } from '../util/cart';
import FirebaseService from '../services/firebase.service';
import { handleZeroDayMigration } from '../util/migrations';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [loadingSession, setLoadingSession] = useState(true);
  const [user] = useAuthState(auth);
  const [localUser, setLocalUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [cart, setCart] = useState(DEFAULT_CART);
  const [checkout, setCheckout] = useState(false);

  const firebaseService = FirebaseService.getInstance();

  useEffect(() => {
    const localUserData = JSON.parse(localStorage.getItem('user')) ?? null;
    const localCartData =
      JSON.parse(localStorage.getItem('cart')) ?? DEFAULT_CART;
    setLocalUser(localUserData);
    setCart(localCartData);
    setLoadingSession(false);
  }, []);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const uid = localUser?.uid ?? user?.uid;
        setLoading(uid ? true : false);
        if (!uid || loadingSession) return;
        const profileRes = await firebaseService.findProfile(uid);
        let [cartRes] = await firebaseService.findOrCreateCart(profileRes);
        await handleCartMigration(cartRes, cart, profileRes);
      } catch {
        logOut();
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [loadingSession, localUser, user]);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const cleanUp = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('cart');
    localStorage.removeItem('discount-code');
    setLocalUser(null);
    setProfile(null);
    setCart(DEFAULT_CART);
  };

  const logOut = async () => await signOut(auth).then(() => cleanUp());

  const handleCartMigration = async (cartRes, cart, profileRes) => {
    const mergedCart = handleZeroDayMigration({
      ...cartRes,
      items: mergeCartItems(cartRes?.items, cart?.items),
    });
    if (mergedCart !== cartRes)
      await firebaseService.updateCart(profileRes, mergedCart);
    setProfile(profileRes);
    setCart(mergedCart);
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        localUser,
        profile,
        cart,
        setCart,
        checkout,
        setCheckout,
        logOut,
      }}
    >
      {loading ? <PageLoader /> : children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
