import { useState, useEffect } from 'react';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { MAX_PRODUCTS_PER_PAGE } from '../../../contants';
import { cancelAllRequests } from '../../../util/axios-req';
import { CustomToast } from '../../../components/Notifications/CustomToast';
import TextLoader from '../../../components/Loaders/TextLoader/TextLoader';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { AmazonProductCard } from '../../../components/Products/Card/Amazon/AmazonProductCard';
import BannerCarousel from '../../../components/BannerCarousel/BannerCarousel';
import AmazonService from '../../../services/amazon.service';
import FirebaseService from '../../../services/firebase.service';

export function DashboardPage() {
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [trips, setTrips] = useState([]);
  const [categoryWeights, setCategoryWeights] = useState([]);
  const [productHistory, setProductHistory] = useState([]);
  const [pageLimit, setPageLimit] = useState(MAX_PRODUCTS_PER_PAGE);

  const location = useLocation();
  const navigate = useNavigate();
  const amazonService = AmazonService.getInstance();
  const firebaseService = FirebaseService.getInstance();

  const page = parseInt(new URLSearchParams(location.search).get('p')) || 1;

  useEffect(() => {
    const fetchData = async () => {
      if (loading) return;
      try {
        resetValues();
        setLoading(true);
        const productPromise = searchDeals();
        const driversPromise = searchDrivers();
        const tripsPromise = searchTrips();
        const productHistory = searchProductHistory();
        const categoryWeightsPromise = searchCategoryWeights();
        await Promise.all([
          productPromise,
          driversPromise,
          tripsPromise,
          productHistory,
          categoryWeightsPromise,
        ]);
      } catch {
        CustomToast('error', 'Error al buscart ofertas');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [page]);

  const resetValues = async () => {
    cancelAllRequests();
    setSearchResults([]);
    setPageLimit(MAX_PRODUCTS_PER_PAGE);
  };

  const searchDeals = async () => {
    try {
      const response = await amazonService.deals(page);
      if (response?.data?.deals?.length > 0) {
        const deals = response.data.deals.map((item) => ({
          ...item,
          asin: item.product_asin,
          product_title: item.deal_title,
          product_photo: item.deal_photo,
        }));
        setSearchResults(deals);
      }
    } catch {
      CustomToast('error', 'Error al buscar ofertas');
    }
  };

  const searchDrivers = async () => {
    try {
      const response = await firebaseService.findDrivers();
      if (response.length > 0) setDrivers(response);
    } catch {
      CustomToast('error', 'Error al buscar conductores');
    }
  };

  const searchTrips = async () => {
    try {
      const response = await firebaseService.findTrips();
      if (response.length > 0) setTrips(response);
    } catch {
      CustomToast('error', 'Error al buscar viajes');
    }
  };

  const searchCategoryWeights = async () => {
    try {
      const response = await firebaseService.findActiveCategoryWeights();
      if (response.length > 0) setCategoryWeights(response);
    } catch {
      CustomToast('error', 'Error al buscar pesos de categorías');
    }
  };

  const searchProductHistory = async () => {
    try {
      const response = await firebaseService.findHistoryProducts();
      if (response.length > 0) setProductHistory(response);
    } catch {
      CustomToast('error', 'Error al buscar historial de productos');
    }
  };

  const addMoreProducts = () => {
    setPageLimit((prev) => prev + MAX_PRODUCTS_PER_PAGE);
  };

  const handleIncreasePage = (increase) => {
    if (increase) navigate(`/?p=${page + 1}`);
    else if (page > 1) navigate(`/?p=${page - 1}`);
  };

  if (loading)
    return (
      <div className="loadingPage">
        <TextLoader
          texts={[
            'Buscando en nuestra base de datos',
            'Definiendo precios todo incluido',
            'Calculando fecha de entrega',
            'Ya casi terminamos',
            'Personalizando tu página de productos',
          ]}
        />
      </div>
    );

  const productCardComponents = {
    amazon: (item, index) => (
      <AmazonProductCard
        key={index}
        productDefault={item}
        trips={trips}
        drivers={drivers}
        productHistory={productHistory}
        categoryWeights={categoryWeights}
      />
    ),
  };

  return (
    <>
      <BannerCarousel />
      <h1
        className="text-center"
        style={{ marginTop: '1rem', marginBottom: '1rem' }}
      >
        Ofertas del día
      </h1>
      <div id="productGrid">
        {searchResults
          .slice(0, pageLimit)
          .map((item, index) =>
            productCardComponents[item?.source]
              ? productCardComponents[item?.source](item, index)
              : null,
          )}
      </div>
      {searchResults?.length === 0 ? (
        <p>No se encontraron resultados.</p>
      ) : (
        <>
          {searchResults.length > pageLimit && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '15px',
              }}
            >
              <button className="btn btn-warning" onClick={addMoreProducts}>
                Ver más
              </button>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '2rem',
            }}
          >
            <FaChevronLeft
              onClick={() => handleIncreasePage(false)}
              style={{
                color: '#FFD000',
                cursor: 'pointer',
                width: 25,
                height: 25,
              }}
            />
            <p
              style={{
                marginBottom: 0,
                marginLeft: '1rem',
                marginRight: '1rem',
              }}
            >
              {page}
            </p>
            <FaChevronRight
              onClick={() => handleIncreasePage(true)}
              style={{
                color: '#FFD000',
                cursor: 'pointer',
                width: 25,
                height: 25,
              }}
            />
          </div>
        </>
      )}
    </>
  );
}
