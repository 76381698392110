import { useEffect, useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import { calculateAmazonPrice } from '../../../../util/price';
import { formatDateSpanish, parseDate } from '../../../../util/date';
import { findHistoryItem, getMaxWeight } from '../../../../util/array';
import {
  getWeightFromProductDetails,
  getWeigthFromProductDimensions,
} from '../../../../util/amazon';
import ProductReportModal from '../../Modals/ReportModal/ProductReportModal';
import TextLoader from '../../../Loaders/TextLoader/TextLoader';
import { useNavigate } from 'react-router-dom';
import AmazonService from '../../../../services/amazon.service';
import StarRating from '../../../Reviews/StarRating/StarRating';
import AmazonAddModal from '../../Modals/Amazon/AmazonAddModal';
import { MAX_PRODUCT_PRICE } from '../../../../contants';

export const AmazonProductCard = ({
  productDefault,
  trips = [],
  drivers = [],
  productHistory = [],
  categoryWeights = [],
}) => {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [productReportModal, setProductReportModal] = useState(false);
  const [productAddModal, setProductAddModal] = useState(false);

  const navigate = useNavigate();
  const amazonService = AmazonService.getInstance();

  useEffect(() => {
    const fetchData = async () => {
      if (!productDefault || product) return;
      try {
        const response = await amazonService.detail(productDefault.asin);
        setProduct({
          ...response.data,
          ...productDefault,
        });
      } catch {
        return null;
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [productDefault]);

  const colorOptions = useMemo(() => {
    if (!product) return null;
    return product?.product_variations?.color;
  }, [product]);

  const sizeOptions = useMemo(() => {
    if (!product) return null;
    return product?.product_variations?.size;
  }, [product]);

  const salePrice = useMemo(() => {
    if (!product) return null;
    const asinProduct = product?.asin;
    const categoryList =
      product?.category_path?.map((value) => value.id?.toLowerCase()) ?? [];
    let price = parseFloat(product?.product_price?.replace(/[^0-9.-]+/g, ''));
    // History details
    const historyItem = findHistoryItem(productHistory, asinProduct);
    let historyWeight = historyItem?.currentWeigth ?? null;
    let historyPrice = historyItem?.price;
    if (
      (!price && !historyPrice) ||
      (price && parseFloat(price) > MAX_PRODUCT_PRICE) ||
      (historyPrice && parseFloat(historyPrice) > MAX_PRODUCT_PRICE)
    )
      return null;
    if (historyPrice) price = parseFloat(historyPrice);
    // Amazon details
    let weigthAmazon = getWeightFromProductDetails(product);
    const shippingAmazon = 0;
    // Dimension details
    let weigthDimension = getWeigthFromProductDimensions(product);
    // Default weight
    let weightDefault = getMaxWeight(categoryWeights, categoryList);
    if (historyWeight) {
      if (parseFloat(historyWeight) > 15) return null;
      if (parseFloat(historyWeight) < 0.25) historyWeight = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(historyWeight) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    if (weigthAmazon) {
      if (parseFloat(weigthAmazon) > 15) return null;
      if (parseFloat(weigthAmazon) < 0.25) weigthAmazon = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(weigthAmazon) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    if (weigthDimension) {
      if (parseFloat(weigthDimension) > 15) return null;
      if (parseFloat(weigthDimension) < 0.25) weigthDimension = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(weigthDimension) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    if (weightDefault) {
      if (parseFloat(weightDefault) > 15) return null;
      if (parseFloat(weightDefault) < 0.25) weightDefault = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(weightDefault) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    return null;
  }, [product, productHistory, categoryWeights]);

  const originalPrice = useMemo(() => {
    if (!product) return null;
    const asinProduct = product?.asin;
    const categoryList =
      product?.category_path?.map((value) => value.id?.toLowerCase()) ?? [];
    let price = parseFloat(
      product?.product_original_price?.replace(/[^0-9.-]+/g, ''),
    );
    // History details
    const historyItem = findHistoryItem(productHistory, asinProduct);
    let historyWeight = historyItem?.currentWeigth ?? null;
    let historyPrice = historyItem?.price;
    if (
      (!price && !historyPrice) ||
      (price && parseFloat(price) > MAX_PRODUCT_PRICE) ||
      (historyPrice && parseFloat(historyPrice) > MAX_PRODUCT_PRICE)
    )
      return null;
    if (historyPrice) price = parseFloat(historyPrice);
    // Amazon details
    let weigthAmazon = getWeightFromProductDetails(product);
    const shippingAmazon = 0;
    // Dimension details
    let weigthDimension = getWeigthFromProductDimensions(product);
    // Default weight
    let weightDefault = getMaxWeight(categoryWeights, categoryList);
    if (historyWeight) {
      if (parseFloat(historyWeight) > 15) return null;
      if (parseFloat(historyWeight) < 0.25) historyWeight = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(historyWeight) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    if (weigthAmazon) {
      if (parseFloat(weigthAmazon) > 15) return null;
      if (parseFloat(weigthAmazon) < 0.25) weigthAmazon = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(weigthAmazon) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    if (weigthDimension) {
      if (parseFloat(weigthDimension) > 15) return null;
      if (parseFloat(weigthDimension) < 0.25) weigthDimension = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(weigthDimension) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    if (weightDefault) {
      if (parseFloat(weightDefault) > 15) return null;
      if (parseFloat(weightDefault) < 0.25) weightDefault = 0.25;
      return calculateAmazonPrice(
        price,
        parseFloat(weightDefault) ?? 0,
        shippingAmazon ?? 0,
      );
    }
    return null;
  }, [product, productHistory, categoryWeights]);

  const estimateDeliveryDate = useMemo(() => {
    let dateAmazon = product?.primary_delivery_time || '';
    if (!dateAmazon) {
      const nowDate = new Date();
      nowDate.setDate(nowDate.getDate() + 15);
      return nowDate;
    }
    const date = parseDate(dateAmazon);
    const dateOrder = [...date].reverse()[0];
    const filterTrips = trips.filter(
      (value) => value?.date > date?.[0]?.toISOString(),
    );
    const dateOptimization = filterTrips.find((info) => {
      const userInfo = drivers.find((user) => user?.uid === info.travelerUid);
      if (!userInfo) return false;
      const dayBeforeTravel = userInfo.dayBeforeTravel || 3;
      const dayTravel = new Date(info.date);
      dayTravel.setDate(dayTravel.getDate() - dayBeforeTravel);
      if (dateOrder < dayTravel) return true;
      return false;
    });
    if (!dateOptimization) {
      const currentDate = new Date(dateOrder);
      currentDate.setDate(currentDate.getDate() + 15);
      return currentDate;
    }
    const dateTravel = new Date(dateOptimization.date);
    const userInfo = drivers.find(
      (user) => user?.uid === dateOptimization.travelerUid,
    );
    const daysAfterTravel = userInfo.dayAfterTravel || 3;
    dateTravel.setDate(dateTravel.getDate() + parseFloat(daysAfterTravel));
    return dateTravel;
  }, [product, trips, drivers]);

  return (
    <div
      className="product"
      onClick={() => navigate(`/detail/${productDefault.asin}`)}
    >
      <div className="productImageContainer">
        <img
          alt="productImage"
          src={productDefault.product_photo}
          className="productImage"
        />
      </div>
      <div className="productContent">
        <p className="productName">{productDefault.product_title}</p>

        {productDefault?.product_star_rating && (
          <div className="rating-container">
            <StarRating
              rating={productDefault?.product_star_rating}
              style={{ margin: 0, padding: 0 }}
            />
          </div>
        )}

        {estimateDeliveryDate && (
          <p className="productDeliveryDate">
            Fecha Estimada de entrega:{' '}
            <span className="bold-text">
              {formatDateSpanish(estimateDeliveryDate)}
            </span>
          </p>
        )}
        {loading ? (
          <TextLoader
            style={{
              alignSelf: 'center',
              width: '100%',
              padding: '0.5rem 2rem',
              fontSize: '16px',
            }}
            texts={['Calculando precio todo incluido']}
          />
        ) : (
          <>
            {salePrice ? (
              <>
                <p className="productPrice">
                  {originalPrice > salePrice && (
                    <span className="productRRP">
                      <span className="productPriceSymbol">$</span>
                      <span className="productPriceWhole">
                        {originalPrice.toFixed(2)}
                      </span>
                    </span>
                  )}
                  <span className="productDiscountedPrice">
                    <span className="productPriceSymbol">$</span>
                    <span className="productPriceWhole">
                      {salePrice.toFixed(2)}
                    </span>
                  </span>
                </p>
                <p className="priceMessage">(Precio todo incluido)</p>

                <Button
                  className="primaryBtn"
                  onClick={(e) => {
                    e.stopPropagation();
                    setProductAddModal(true);
                  }}
                >
                  Agregar
                </Button>
              </>
            ) : (
              <Button
                className="primaryBtn"
                style={{ marginTop: '0.5rem' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setProductReportModal(true);
                }}
              >
                Solicitar cotización
              </Button>
            )}
          </>
        )}
      </div>
      <ProductReportModal
        isOpen={productReportModal}
        toggle={() => setProductReportModal((prev) => !prev)}
        product={{
          url: product?.product_url || '',
          title: product?.product_title || '',
          image: product?.product_photo || '',
        }}
      />
      <AmazonAddModal
        isOpen={productAddModal}
        toggle={() => setProductAddModal((prev) => !prev)}
        productDefault={product}
        trips={trips}
        drivers={drivers}
        productHistory={productHistory}
        categoryWeights={categoryWeights}
        colorOptions={colorOptions}
        sizeOptions={sizeOptions}
        setProductReportModal={setProductReportModal}
      />
    </div>
  );
};
